.back-office-panel {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 300px;

    .box-responsive {
        width: 100%;
        overflow-x: auto;
        background-color: var(--app-bg-primary-contrast);
        padding: 15px;
        border-radius: 5px;
        margin: 6px 0;
    }

    ul.pagination {
        margin-bottom: 0px !important;
    }
    // .ql-editor.ql-blank {
    //     // min-height: 300px;
    //     min-height: 280px;
    // }

    color: var(--app-main-color);
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: #f7f7f7;
    }
    .card {
        border: none !important;
    }
    .card-header {
        background: var(--app-main-color);
        // background: #0b7caf;
        color: #fff;
        font-size: 20px;
        padding-left: 10px;
    }
    .card-body {
        padding: 0px !important;
    }
    label {
        font-size: 12px;
        color: #000;
        margin-bottom: 0;
    }
    .time-custom table {
        border: none;
        width: 100%;
    }
    .time-custom button {
        outline: none !important;
        border: 1px solid #cccccc;
        color: black;
        font-size: 15px;
        text-transform: uppercase;
        padding: 8px 30px;
        border-radius: 25px;
        margin: 3px;
    }
    .time-custom input {
        width: 100%;
    }

    .mendatory {
        color: red;
    }

    .title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title-wrapper .title {
        color: var(--ap-red);
    }
    .table td,
    .table th {
        vertical-align: middle !important;
        border-top: none !important;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        padding-bottom: 0px !important;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
        padding-top: 5px !important;
    }

    .ro {
        border-radius: 0px !important;
        padding: 1px 12px !important;
    }

    .stick-top {
        position: sticky !important;
        top: 0px !important;
        z-index: 10;
        background-color: rgb(255 255 255 / 85%);
        // border-bottom: 3px solid var(--ap-secondary);
    }
    .angular-editor-toolbar-set .fa {
        color: black;
    }
    .ae-font .ae-picker-label {
        color: black;
    }
    .ae-font .ae-picker-options .ae-picker-item {
        color: #000000;
    }
    .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
        border-radius: 2px;
    }
    .angular-editor-toolbar-set button {
        border-radius: 2px;
        padding: 1px 5px;
    }

    .ng-select .ng-select-container {
        min-height: 31px;
    }

    .ng-select.ng-select-single .ng-select-container {
        height: 31px;
    }

    .ng-select .ng-select-container .ng-value-container .ng-input > input {
        height: 26px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container{
        padding-top: 0 !important;
    }
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        margin-bottom: 0 !important;
    }
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        top: 3px !important;
    }

    @media (max-width: 425px) {
        .box {
            padding: 10px;
        }
    }

    // button loader
    .in-progress span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        border-radius: 50%;
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #fff;
        margin: 0 3px;
    }

    .in-progress span:nth-child(2) {
        animation-delay: 0.2s;
    }

    .in-progress span:nth-child(3) {
        animation-delay: 0.4s;
    }
    @keyframes blink {
        0% {
            opacity: 0.2;
        }

        20% {
            opacity: 1;
        }

        100% {
            opacity: 0.2;
        }
    }
}
