.back-office-panel, .website {
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        padding-right: 5px;
        -webkit-overflow-scrolling: touch;
    }

    table.cg {
        $tableBackgroundColor: var(--ap-bg-primary);;
        $alternate-row-opacity: 0.1;
        color: var(--ap-bg-primary-contrast);

        margin: 0 !important;
        width: 100%;
        background-color: #{$tableBackgroundColor};
        border-radius: 10px 10px 10px 10px !important;

        @each $style in $styles {
            &.#{$style}.alternate-rows tr:nth-child(even) {
                background-color: rgbaColor(#{$style}, #{$alternate-row-opacity});
            }

            &.#{$style}.alternate-cols td:nth-child(even) {
                background-color: rgbaColor(#{$style}, #{$alternate-row-opacity});
            }

            &.#{$style} thead th {
                color: var(--ap-#{$style});
                font-weight: 600;
                font-size: 14px;
                padding-top: 0px;
                padding-left: 5px;
                padding-bottom: 10px;
                box-shadow: inset 0px -3px 0px 0px var(--ap-#{$style});
            }
        }

        thead.sticky {
            position: sticky;
            top: 0;
            z-index: 99;
            background-color: #{$tableBackgroundColor};
        }

        tbody {
            td {
                font-size: 14px;
                padding: 5px;
                border-bottom: none;
            }
        }
    }
    // Printable Tables
    table.cg-printable {
        width: 100%;
        border: 1px solid #666;
        th {
            border: 1px solid #666;
            border-bottom: 3px solid #666;
            padding: 0.3rem 0.3rem;
            font-size: 14px;
        }
        td {
            border: 1px solid #666;
            padding: 0.3rem 0.3rem;
            font-size: 13px;
        }
        &.invoice td {
            border: none;
            border-right: 1px solid #666;
            padding: 0.3rem 0.3rem;
            font-size: 13px;
        }
    }


    .back-office-panel-cg {
        .table-responsive {
            background-color: #fff;
            padding: 5px 10px;
            padding-top: 0;
            border-radius: 5px;
            margin: 6px 0;
        }
        .table.table-striped {
            background-color: #fff;
            border-radius: 10px 10px 10px 10px !important;
        }
        .table {
            margin: 0 !important;
        }
        .table thead th {
            color: var(--ap-secondary);
            font-weight: 600;
            font-size: 14px;
            padding: 10px 0.75rem 0;
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 99;
            padding-top: 10px;
            padding-bottom: 10px;
            box-shadow: inset 0px -3px 0px 0px var(--ap-secondary)
        }
        .table tbody td {
            font-size: 14px;
            padding: 0.2rem 0.7rem !important;
            border-bottom: none;
        }
        .table.bordered tbody td {
            border: 1px solid;
        }

        // Printable Tables
        table.printable {
            width: 100%;
            border: 1px solid #666;
        }

        table.printable th {
            border: 1px solid #666;
            border-bottom: 3px solid #666;
            padding: 0.3rem 0.3rem;
            font-size: 14px;
        }

        table.printable td {
            border: 1px solid #666;
            padding: 0.3rem 0.3rem;
            font-size: 13px;
        }

        table.printable.invoice td {
            border: none;
            border-right: 1px solid #666;
            padding: 0.3rem 0.3rem;
            font-size: 13px;
        }
    }
}

